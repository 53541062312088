import { lazy } from "react";
import { RouteType } from "../types";

const Attendance = lazy(() => import("../pages/admin/Attendance/Attendance"));
const Employee = lazy(() => import("../pages/admin/Employee/Employee"));
const ViewEmployee = lazy(
  () => import("../components/common/ViewEmployee/ViewEmployee")
);
const LocationManagement = lazy(
  () => import("../pages/admin/LocationManagement/LocationManagement")
);
const MessageCreation = lazy(
  () => import("../pages/admin/Message/MessageCreation")
);
const OfficeManagement = lazy(
  () => import("../pages/admin/OfficeManagement/OfficeManagement")
);
const RoleManagement = lazy(
  () => import("../pages/admin/RoleManagement/RoleManagement")
);

const NotFound = lazy(() => import("../pages/NotFound"));
const AdminLogin = lazy(() => import("../pages/admin/Login"));
const AdminDashboard = lazy(() => import("../pages/admin/Dashboard"));

// auth routers
export const sidebarRouters: RouteType[] = [
  {
    path: "/admin/dashboard",
    element: AdminDashboard,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/employee",
    element: Employee,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/employee/:id",
    element: ViewEmployee,
    roles: ["ADMIN"],
  },
  {
    path: "/admin/attendance",
    element: Attendance,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/office-management",
    element: OfficeManagement,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/location-management",
    element: LocationManagement,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/role-management",
    element: RoleManagement,
    roles: ["ADMIN"],
  },

  {
    path: "/admin/message-creation",
    element: MessageCreation,
    roles: ["ADMIN"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/admin/login",
    element: AdminLogin,
  },
  {
    path: "*",
    element: NotFound,
  },
];
