import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../config/api";
import { BASE_API } from "../../../constants/apiUrls";
import { AUTH_SAVE } from "../../../constants/constants";
import { toast } from "../../../lib/toast";
import {
  AuthUser,
  CommonOthers,
  ErrorMessage,
  LoginActionType,
  LoginData,
  PostRequest,
} from "../../../types";

// login user
export const loginUserAsync = createAsyncThunk<
  LoginActionType<AuthUser>,
  PostRequest<LoginData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "login/loginUser",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      const loginData = await AXIOS.post(BASE_API.login, data);

      const responseData = {
        token: loginData?.data?.data?.access_token,
        data: loginData?.data?.data?.user,
      };

      if (responseData?.token && responseData?.data?.email) {
        AXIOS.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${responseData?.token}`;
        AXIOS.defaults.headers.common["ngrok-skip-browser-warning"] = true;

        localStorage.setItem(`${AUTH_SAVE}`, JSON.stringify(responseData));
      }

      reRender(true);
      toast("success", "Login successfully");

      return responseData as LoginActionType<AuthUser>;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Login fail");
      return rejectWithValue(error);
    }
  }
);
