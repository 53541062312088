import { createSlice } from "@reduxjs/toolkit";
import {
  AttendanceData,
  AttendanceInitialStatus,
} from "../../../types/attendance/attendance";
import {
  getAllAttendanceAsync,
  singleAttendanceAsync,
  syncOfflineAttendanceAsync,
} from "./AttendanceApi";

const initialState: AttendanceInitialStatus<AttendanceData> = {
  loading: false,
  manageLoading: false,
  attendanceData: [],
  singleAttendanceData: null,
  countAttendance: 0,
};

const attendanceSlice = createSlice({
  name: "admin-attendance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all
    builder
      .addCase(getAllAttendanceAsync.pending, (state) => {
        state.loading = true;
        state.attendanceData = [];
      })
      .addCase(getAllAttendanceAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.attendanceData = action.payload.data;
        state.countAttendance = action.payload.length;
      })
      .addCase(getAllAttendanceAsync.rejected, (state) => {
        state.loading = false;
        state.attendanceData = [];
        state.countAttendance = 0;
      });

    // single
    builder
      .addCase(singleAttendanceAsync.pending, (state) => {
        state.manageLoading = true;
        state.singleAttendanceData = null;
      })
      .addCase(singleAttendanceAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.singleAttendanceData = action.payload;
      })
      .addCase(singleAttendanceAsync.rejected, (state) => {
        state.manageLoading = false;
        state.singleAttendanceData = null;
      });

    builder
      .addCase(syncOfflineAttendanceAsync.pending, (state) => {
        state.manageLoading = true;
      })
      .addCase(syncOfflineAttendanceAsync.fulfilled, (state) => {
        state.manageLoading = false;
      })
      .addCase(syncOfflineAttendanceAsync.rejected, (state) => {
        state.manageLoading = false;
      });
  },
});

export default attendanceSlice;
