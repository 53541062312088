import { createSlice } from "@reduxjs/toolkit";
import {
  EmployeeAttendanceSummeryData,
  EmployeeData,
  EmployeeInitialStatus,
  EmployeePersonalData,
} from "../../../types/employee/employee";
import {
  getAttendanceLogDataAsync,
  getAttendanceSummeryDataAsync,
  getEmployeePersonalDataAsync,
  syncUserAsync,
  updateProfileAsync,
} from "./EmployeeApi";

const initialState: EmployeeInitialStatus<
  EmployeeData,
  EmployeePersonalData,
  EmployeeAttendanceSummeryData,
  any
> = {
  loading: false,
  manageLoading: false,
  uploadLoading: false,
  syncLoading: false,
  employeeData: [],
  employeePersonalData: null,
  attendanceSummeryData: null,
  attendanceLogData: null,
  countEmployee: 0,
};

const employeeSlice = createSlice({
  name: "admin-employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // // get all
    // builder
    //   .addCase(getAllEmployeeAsync.pending, (state) => {
    //     state.loading = true;
    //     state.employeeData = [];
    //   })
    //   .addCase(getAllEmployeeAsync.fulfilled, (state, action) => {
    //     state.loading = false;
    //     state.employeeData = action.payload.data;
    //     state.countEmployee = action.payload.length;
    //   })
    //   .addCase(getAllEmployeeAsync.rejected, (state) => {
    //     state.loading = false;
    //     state.employeeData = [];
    //     state.countEmployee = 0;
    //   });

    // get profile data upper part
    builder
      .addCase(getEmployeePersonalDataAsync.pending, (state) => {
        state.manageLoading = true;
        state.employeePersonalData = null;
      })
      .addCase(getEmployeePersonalDataAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.employeePersonalData = action.payload;
      })
      .addCase(getEmployeePersonalDataAsync.rejected, (state) => {
        state.manageLoading = false;
        state.employeePersonalData = null;
      });

    // get attendance summery data middle part
    builder
      .addCase(getAttendanceSummeryDataAsync.pending, (state) => {
        state.manageLoading = true;
        state.employeePersonalData = null;
      })
      .addCase(getAttendanceSummeryDataAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.attendanceSummeryData = action.payload;
      })
      .addCase(getAttendanceSummeryDataAsync.rejected, (state) => {
        state.manageLoading = false;
        state.employeePersonalData = null;
      });

    // get attendance log data (lower part)
    builder
      .addCase(getAttendanceLogDataAsync.pending, (state) => {
        state.manageLoading = true;
        state.attendanceLogData = null;
      })
      .addCase(getAttendanceLogDataAsync.fulfilled, (state, action) => {
        state.manageLoading = false;
        state.attendanceLogData = action.payload;
      })
      .addCase(getAttendanceLogDataAsync.rejected, (state) => {
        state.manageLoading = false;
        state.attendanceLogData = null;
      });
    // update profile
    builder
      .addCase(updateProfileAsync.pending, (state) => {
        state.uploadLoading = true;
      })
      .addCase(updateProfileAsync.fulfilled, (state, action) => {
        state.uploadLoading = false;
      })
      .addCase(updateProfileAsync.rejected, (state) => {
        state.uploadLoading = false;
      });
    builder
      .addCase(syncUserAsync.pending, (state) => {
        state.syncLoading = true;
      })
      .addCase(syncUserAsync.fulfilled, (state, action) => {
        state.syncLoading = false;
      })
      .addCase(syncUserAsync.rejected, (state) => {
        state.syncLoading = false;
      });
  },
});

export default employeeSlice;
